import { toggle, payload, update, ACTION_NAMES_CREATOR } from '@cnd/redash/dist/funstore'

import initialState, {
  DoubleSidebarBottomState,
  InitialState,
  SidebarBottomState,
  UserOrderState,
  UserState,
} from './initialState'
import { uuid, createError, unsetR, isError, isAxiosError, getAxiosError } from '@cnd/redash'

import createOrder, { CURRENT_ORDER_VERSION } from './createOrder'
import { isItemAMembership, addOrderDerivedOrderValues } from '@cnd/common/functions/menu'
import { Order, ProductOnMenu, User } from '@cnd/common/types'
import {
  mergeDeepLeft,
  dissocPath,
  reduce,
  lensProp,
  set,
  mergeDeepRight,
  pipe,
  lensPath,
  curry,
  sortBy,
  prop,
  values,
  reject,
  clone,
  dissoc,
} from 'ramda'
import { getDerivedBusinessHours } from './useBusinessHours'
import { addMemberPricesToMenu } from '@cnd/common/functions/menu'
import { DeepPartial } from '@cnd/common/types/helpers'

const mergeDeepRightT = <A extends object>(a: A, b): A => mergeDeepRight(a, b) as A
const mergeDeepAll = reduce(mergeDeepRight, {})
const orderLens = lensProp<UserState, 'order'>('order')

const setOrderDerivedValues = (user: UserState): UserState =>
  set(orderLens, addOrderDerivedOrderValues(user.order), user)

const mergeUserAndSetOrderDerivedValues = (
  user: UserState,
  order: DeepPartial<UserState['order']>
): UserState => setOrderDerivedValues(mergeDeepRight(user, { order }) as UserState)

const setEval = curry((a, y, x) => set(a, typeof y === 'function' ? y(x) : y, x))

export const resetAllPending = pipe(
  set(lensPath(['route', 'redirectAfterOrderPaymentComplete']), null),
  set(lensPath(['route', 'onRouteChangeCache']), null),
  set(lensPath(['locations', 'pending']), false),
  set(lensPath(['user', 'loading']), false),
  set(lensPath(['user', 'application', 'pending']), false),
  set(lensPath(['user', 'paymentMethodsPending']), false)
)

export const fixFuckUp = pipe(
  setEval(lensPath(['order']), (state) =>
    state?.order?.version !== CURRENT_ORDER_VERSION
      ? createOrder('hiro', state?.defaultDeliveryAddress)
      : state?.order
  ),
  dissocPath(['user']),
  dissocPath(['route']),
  dissocPath(['sidebar']),
  dissocPath(['onLoginReturnTo']),
  dissocPath(['sidebarBottom']),
  dissocPath(['showHeader']),
  dissocPath(['appVersion']),
  dissocPath(['selectedMenuItem']),
  dissocPath(['auth']),
  dissocPath(['locations']),
  set(lensPath(['order', 'membership']), 'hiro'),
  set(lensPath(['membership']), {
    freeOrderProbability: 1,
    shortname: 'hiro',
    confirmed: true,
    selected: true,
  }),
  setEval(lensPath(['order']), (state) => addOrderDerivedOrderValues(state.order)),
  set(lensPath(['pending']), false),
  set(lensPath(['error']), null),
  set(lensPath(['application', 'pending']), false),
  set(lensPath(['application', 'error']), null),
  set(lensPath(['defaultDeliveryAddress', 'pending']), false),
  set(lensPath(['defaultDeliveryAddress', 'error']), null),
  set(lensPath(['loading']), false),
  set(lensPath(['loadingError']), null)
) as any

// On order payment complete
// Set order on user
// Set to navigate user to page where order order is being placed

const initialConfiguration = {
  menu: {
    initialState: clone(initialState.menu),
    actions: {
      SET_MENU: (_, { menu, membership }) => ({
        discount: _.discount,
        items: addMemberPricesToMenu(
          membership,
          _.discount || 0.5
        )(reject((i: ProductOnMenu) => i.enabled === false, values(menu))),
        pending: false,
        error: null,
      }),
      SET_ORDER_DELIVERY_STATE: (state, { deliveryLocation }) =>
        mergeDeepRight(state, {
          discount: deliveryLocation?.['discount'] || 0,
          items: addMemberPricesToMenu(
            'hiro',
            (deliveryLocation?.['discount'] || 0.5) as number
          )(state.items),
        }),
    },
  },
  route: {
    initialState: clone(initialState.route),
    actions: {
      SET_CHECKOUT_STEP: update('checkoutStep'),
      RESET_BOTTOMBAR: (state) => ({ ...state, checkoutStep: null }),
      CLEAR_ORDER: (state) => ({
        ...state,
        redirectAfterOrderPaymentComplete: null,
        onRouteChangeCache: null,
      }),
      ORDER_PLACED: (state) => ({ ...state, redirectAfterOrderPaymentComplete: null }),
      SET_REDIRECT_AFTER_LOGIN_ROUTE: update('redirectAfterLogin'),
      SET_REDIRECT_AFTER_SIGNUP_ROUTE: update('redirectAfterSignUp'),
      SET_PREVIOUS_ROUTE: update('previous'),
      SET_CURRENT_ROUTE: update('current'),
      LOGOUT_FULFILLED: initialState.route,
    },
  },
  onLoginReturnTo: {
    initialState: clone(initialState.onLoginReturnTo),
    actions: {
      SET_ON_LOGIN_RETURN_TO: payload,
    },
  },
  ordersInProgress: {
    initialState: clone(initialState.ordersInProgress),
    actions: {
      CLEAR_ORDERS_IN_PROGRESS: () => clone(initialState.ordersInProgress),
      CONFIRM_ORDER_FULFILLED: (state, order) => mergeDeepRight(state, { [order.id]: order }),
      SET_ORDER_IN_PROGRESS: (state, order) => mergeDeepRight(state, { [order.id]: order }),
      SET_ORDER_NOT_IN_PROGRESS: (state, orderId) => dissoc(orderId, state),
    },
  },
  sidebar: {
    initialState: clone(initialState.sidebar),
    actions: {
      TOGGLE_SIDEBAR: toggle,
      SET_SIDEBAR: payload,
    },
  },
  sidebarBottom: {
    initialState: clone(initialState.sidebarBottom),
    actions: {
      RESET_BOTTOMBAR: clone(initialState.sidebarBottom),
      REPLACE_BOTTOMBAR: mergeDeepRight,
      REMOVE_USER_PAYMENT_METHOD_FULFILLED: clone(initialState.sidebarBottom),
      ADD_USER_PAYMENT_METHOD_FULFILLED: clone(initialState.sidebarBottom),
      TOGGLE_BOTTOMBAR: (state: SidebarBottomState): SidebarBottomState =>
        mergeDeepRight(state, { isOpen: !state.isOpen, content: !state.isOpen ? '' : state.content }),
    },
  },
  doubleSidebarBottom: {
    initialState: clone(initialState.doubleSidebarBottom),
    actions: {
      RESET_DOUBLE_BOTTOMBAR: clone(initialState.doubleSidebarBottom),
      REPLACE_DOUBLE_BOTTOMBAR: mergeDeepRight,
      REMOVE_USER_PAYMENT_METHOD_FULFILLED: clone(initialState.doubleSidebarBottom),
      ADD_USER_PAYMENT_METHOD_FULFILLED: clone(initialState.doubleSidebarBottom),
      TOGGLE_DOUBLE_BOTTOMBAR: (state: DoubleSidebarBottomState): DoubleSidebarBottomState =>
        mergeDeepRight(state, { isOpen: !state.isOpen, content: !state.isOpen ? '' : state.content }),
    },
  },
  showHeader: {
    initialState: clone(initialState.showHeader),
    actions: {
      SET_SHOW_HEADER: payload,
    },
  },
  appVersion: {
    initialState: clone(initialState.appVersion),
    actions: {
      SET_APP_VERSION: update('current'),
    },
  },
  auth: {
    initialState: clone(initialState.auth),
    actions: {
      LOGOUT_PENDING: mergeDeepLeft({ logoutPending: true, logoutError: false }),
      LOGOUT_REJECTED: mergeDeepLeft({ logoutPending: false, logoutError: true }),
      LOGOUT_FULFILLED: clone(initialState.auth),
      CLEAR_STATE: clone(initialState.auth),
      SET_AUTH_SWITCH: update('switch'),
      SET_AUTH_STATUS: update('authStatus'),
      LOGIN_FULFILLED: mergeDeepLeft({ authStatus: 'requesting-number' }),
    },
  },
  user: {
    initialState: clone(initialState.user),
    actions: {
      SET_APPLICATION_PROBLEM_ANSWERS: (state, { problemAnswers, problemResults }) =>
        mergeDeepRight(state, { application: { problemAnswers, problemResults } }),
      SET_APPLICATION_PROBLEM_QUESTIONS: (state, problemQuestions) =>
        mergeDeepRight(state, { application: { problemQuestions, problemAnswers: {} } }),
      SET_APPLICATION_PERSONALITY_ANSWERS: (state, { personalityAnswers, personalityResults }) =>
        mergeDeepRight(state, { application: { personalityAnswers, personalityResults } }),
      SET_APPLICATION_PERSONALITY_QUESTIONS: (state, personalityQuestions) =>
        mergeDeepRight(state, { application: { personalityQuestions, personalityAnswers: {} } }),
      SET_APPLICATION_HOME_POSTCODE: (state, { homePostcode, commuteTime, commuteTimeInRange }) =>
        mergeDeepRight(state, {
          application: { pending: false, error: null, homePostcode, commuteTime, commuteTimeInRange },
        }),
      SET_APPLICATION_HOME_POSTCODE_PENDING: mergeDeepLeft({ application: { pending: true, error: null } }),
      SET_APPLICATION_HOME_POSTCODE_FULFILLED: (state, { homePostcode, commuteTime, commuteTimeInRange }) =>
        mergeDeepRight(state, {
          application: { pending: false, error: null, homePostcode, commuteTime, commuteTimeInRange },
        }),
      SET_APPLICATION_HOME_POSTCODE_REJECTED: mergeDeepLeft({ application: { pending: false, error: true } }),
      LOGOUT_FULFILLED: clone(initialState.user),
      LOGOUT_PENDING: mergeDeepLeft({ pending: true, error: null }),
      LOGOUT_REJECTED: mergeDeepLeft({ pending: false, error: true }),
      CLEAR_STATE: clone(initialState.user),
      LOGIN_FULFILLED: (state: UserState, user: Partial<UserState>): UserState => {
        const shouldBeloggedInUser = mergeDeepRightT(state, { ...user, error: null, pending: false })
        shouldBeloggedInUser.isLoggedIn = !!shouldBeloggedInUser.accessToken && !!shouldBeloggedInUser.userId
        return shouldBeloggedInUser
      },
      LOGIN_REJECTED: (state: UserState, error): UserState => {
        let newState: Partial<User> = { error, pending: false }
        if (error && error?.error?.code === 401) {
          newState.isLoggedIn = false
        }
        return mergeDeepRightT(state, newState)
      },
      LOGIN_PENDING: mergeDeepLeft({ pending: true, error: null }),
      SET_FIRST_NAME: update('firstName'),
      SET_LAST_NAME: update('lastName'),
      SET_EMAIL: update('email'),
      SET_PHONE_NUMBER: update('phoneNumber'),
      SET_TEMP_PHONE_NUMBER: update('tempPhoneNumber'),
      SET_AUTH_ID_AND_USER_FULFILLED: (state, user) =>
        mergeDeepAll([
          fixFuckUp(state),
          fixFuckUp(user),
          { loaded: true, loading: false, loadingFailed: null },
        ]),
      SET_AUTH_ID_AND_USER_PENDING: mergeDeepLeft({ error: false, loading: true, loadingFailed: null }),
      SET_AUTH_ID_AND_USER_REJECTED: mergeDeepLeft({
        loadingFailed: createError({ type: 'NO_USER', message: 'Failed to setup profile' }),
        loading: false,
      }),
      ADD_USER_PAYMENT_METHOD_PENDING: mergeDeepLeft({
        paymentMethodsPending: true,
        paymentMethodsError: null,
        paymentMethodsRejected: false,
      }),
      ADD_USER_PAYMENT_METHOD_REJECTED: (state, error) =>
        mergeDeepRight(state, {
          paymentMethodsPending: false,
          paymentMethodsError: error,
          paymentMethodsRejected: true,
        }),
      ADD_USER_PAYMENT_METHOD_FULFILLED: (state, paymentMethod) =>
        mergeDeepRight(state, {
          paymentMethodsPending: false,
          paymentMethodsError: null,
          paymentMethodsRejected: false,
          selectedPaymentMethod: paymentMethod.id,
          paymentMethods: {
            [paymentMethod.id]: paymentMethod,
          },
        }),

      REMOVE_USER_PAYMENT_METHOD_PENDING: mergeDeepLeft({
        paymentMethodsPending: true,
        paymentMethodsError: null,
        paymentMethodsRejected: false,
      }),
      REMOVE_USER_PAYMENT_METHOD_REJECTED: (state) =>
        mergeDeepRight(state, {
          paymentMethodsPending: false,
          paymentMethodsError: null, // needs to be set
          paymentMethodsRejected: true,
        }),
      REMOVE_USER_PAYMENT_METHOD_FULFILLED: (state, paymentMethodId) =>
        dissocPath(
          ['paymentMethods', paymentMethodId],
          mergeDeepRight(state, {
            paymentMethodsPending: false,
            paymentMethodsError: null, // needs to be set
            paymentMethodsRejected: false,
          })
        ),

      SET_USER_PAYMENT_METHOD_PENDING: mergeDeepLeft({
        paymentMethodsPending: true,
        paymentMethodsError: null,
        paymentMethodsRejected: false,
      }),
      SET_USER_PAYMENT_METHOD_REJECTED: (state) =>
        mergeDeepRight(state, {
          paymentMethodsPending: false,
          paymentMethodsError: null, // needs to be set
          paymentMethodsRejected: true,
        }),
      SET_USER_PAYMENT_METHOD_FULFILLED: (state, selectedPaymentMethod) =>
        mergeDeepRight(state, {
          selectedPaymentMethod,
          paymentMethodsRejected: false,
          paymentMethodsPending: false,
          paymentMethodsError: null,
        }),
      REMOVE_USER_PAYMENT_METHOD: (state, paymentMethodId) =>
        dissocPath(['paymentMethods', paymentMethodId], state),
      SET_USER: (state: UserState, user) => {
        const newUser = setOrderDerivedValues(mergeDeepRight(state, user))
        newUser.isLoggedIn = !!newUser.accessToken && !!newUser.userId
        return newUser
      },
      POST_USER_PENDING: mergeDeepLeft({ error: false, pending: true }),
      POST_USER_REJECTED: (state, error) =>
        error.name === 'AxiosError' && error.response.status === 401
          ? mergeDeepRight(state, {
            error: createError({ code: 401, message: 'User failed auth' }),
            pending: false,
            isLoggedIn: false,
          })
          : mergeDeepRight(state, { error: createError('Unknown error'), pending: false }),
      POST_USER_FULFILLED: (state, { data: user }) => {
        let newOrder: Partial<Order> = {}
        if (user && user.email) newOrder.customerEmailAddress = user.email
        return mergeDeepRight(state, { ...user, error: false, pending: false, order: newOrder })
      },
      CLEAR_ORDER: (state: UserState) => ({
        ...state,
        order: createOrder('hiro', state.defaultDeliveryAddress),
      }),
      CLEAR_ORDER_DISCOUNT_CODE: (state) =>
        mergeUserAndSetOrderDerivedValues(state, { discountCode: null, discountApplied: 0 }),
      SET_ORDER_DISCOUNT_CODE: (state, discountCode) =>
        mergeUserAndSetOrderDerivedValues(state, { discountCode }),
      ADD_REWARD_TO_ORDER: (state, reward) =>
        mergeUserAndSetOrderDerivedValues(state, { rewards: { [reward.key]: reward } }),
      UPDATE_ORDER_PREP_LOCATION: (state, prepLocation) =>
        mergeUserAndSetOrderDerivedValues(state, { prepLocation }),
      SET_ORDER_DELIVERY_STATE: mergeUserAndSetOrderDerivedValues,
      UPDATE_ORDER_DELIVERY_LOCATION: (user, deliveryLocation) =>
        mergeUserAndSetOrderDerivedValues(user, { deliveryLocation }),
      UPDATE_ORDER_DELIVERY_LOCATION_FULFILLED: (state: UserState, deliveryLocation) =>
        mergeUserAndSetOrderDerivedValues(
          mergeDeepRight(
            state,
            deliveryLocation.saved ? { defaultDeliveryAddress: deliveryLocation } : {}
          ) as any,
          { deliveryLocation: { ...deliveryLocation, pending: false, error: false } }
        ),

      UPDATE_ORDER_DELIVERY_LOCATION_PENDING: (state: UserState) =>
        mergeUserAndSetOrderDerivedValues(state, { deliveryLocation: { pending: true, error: false } }),
      UPDATE_ORDER_DELIVERY_LOCATION_REJECTED: (state) =>
        mergeUserAndSetOrderDerivedValues(state, { deliveryLocation: { pending: false, error: true } }),

      REMOVE_REWARD_FROM_ORDER: (state, rewardId) =>
        mergeUserAndSetOrderDerivedValues(state, unsetR(state.order, `rewards.${rewardId}`)),
      UPDATE_ORDER_DELIVERY_TIME_FULFILLED: (state, order) =>
        mergeUserAndSetOrderDerivedValues(state, {
          proposedDeliveryTimesRejected: false,
          proposedDeliveryTimesPending: false,
          ...order,
        }),

      UPDATE_ORDER_DELIVERY_TIME_PENDING: (state) =>
        mergeUserAndSetOrderDerivedValues(state, {
          proposedDeliveryTimesRejected: false,
          proposedDeliveryTimesPending: true,
        }),

      UPDATE_ORDER_DELIVERY_TIME_REJECTED: (state) =>
        mergeDeepRight(state, {
          order: {
            proposedDeliveryTimesRejected: true,
            proposedDeliveryTimesPending: false,
          },
        }),

      UPDATE_ORDER_PREORDER: (state, isPreOrder) => mergeUserAndSetOrderDerivedValues(state, { isPreOrder }),
      UPDATE_ORDER: mergeUserAndSetOrderDerivedValues,
      UPDATE_ORDER_PAYMENT: (state, payload) =>
        mergeUserAndSetOrderDerivedValues(state, { payment: payload.id, paymentMethod: payload.method }),
      REMOVE_ITEM_FROM_ORDER_BY_KEY: (state, payload) =>
        mergeUserAndSetOrderDerivedValues(state, {
          ...unsetR(state.order, `items[${payload}]`),
        }),
      ADD_ITEM_TO_ORDER: (state: UserState, payload): UserState => {
        const key = uuid()
        return mergeUserAndSetOrderDerivedValues(
          mergeDeepRightT(
            state,
            isItemAMembership(payload)
              ? { membership: { selected: true, confirmed: false, state: payload.shortname } }
              : {}
          ),
          { items: { [key]: { ...payload, key } } }
        )
      },
      CLEAR_PAYMENT_ERROR: (state) =>
        mergeDeepRight(state, {
          paymentMethodsPending: false,
          paymentMethodsError: null,
          order: { paymentError: null, paymentPending: false },
        }),
      PAY_FOR_ORDER_PENDING: mergeDeepLeft({ order: { paymentPending: true, paymentError: null } }),
      PAY_FOR_ORDER_REJECTED: (state, error) => {
        console.log({ error })
        let paymentError = createError(`There was an unknown problem making payment. We're looking into it.`)
        if (isAxiosError(error)) paymentError = getAxiosError(error)
        if (isError(error)) paymentError = error

        console.log('PAY_FOR_ORDER_REJECTED error:', error)
        return mergeDeepAll([state, { order: { paymentPending: false, paymentError } }])
      },
      PAY_FOR_ORDER_FULFILLED: (state, { id, amount, paidTimestamp, paidUnixTimestamp }) =>
        mergeDeepAll([
          state,
          {
            order: {
              state: 'PAID',
              transactionId: id,
              amountPaid: amount,
              paymentPending: false,
              paymentComplete: true,
              statesHistory: {
                [paidTimestamp]: {
                  createdAtTimestamp: paidTimestamp,
                  createdAtUnixTimestamp: paidUnixTimestamp,
                  state: 'PAID',
                },
              },
            } as Partial<Order>,
          },
        ]),
      CONFIRM_ORDER_PENDING: mergeDeepLeft({
        order: { placing: true, placingError: false },
      }),
      CONFIRM_ORDER_REJECTED: mergeDeepLeft({
        order: { placing: false, placingError: true },
      }),
      CLEAR_ORDERS_IN_PROGRESS: (state) =>
        mergeDeepRight(dissoc('order', state), {
          order: createOrder('hiro', state.defaultDeliveryAddress),
        }),
      CONFIRM_ORDER_FULFILLED: (state, order) =>
        mergeDeepRight(dissoc('order', state), {
          order: createOrder('hiro', state.defaultDeliveryAddress),
          orders: {
            [order.id]: order,
          },
        }),
    },
  },
  locations: {
    initialState: initialState.locations,
    actions: {
      CLEAR_LOCATIONS: initialState.locations,
      SET_LOCATION_CLOSEST_TO_USER: (state, location) =>
        mergeDeepRight(state, {
          error: false,
          pending: false,
          closestToUser: location,
        }),
      SET_LOCATION_CLOSEST_TO_USER_PENDING: mergeDeepLeft({ error: false, pending: true }),
      SET_LOCATION_CLOSEST_TO_USER_FULFILLED: (
        state,
        { locations, searchPostcode, searchPostcodeLatLng }
      ) => {
        const closeBy = sortBy(prop('minsFromLocation'), values(mergeDeepRight(state.all, locations)))
        const closestToUser = getDerivedBusinessHours(closeBy[0])
        return mergeDeepRight(state, {
          error: false,
          pending: false,
          closeBy,
          closestToUser,
          searchPreOrder: closestToUser.isShopClosed ? true : false,
          searchPostcode,
          searchPostcodeLatLng,
          postcodeValidError: '',
        })
      },
      SET_BUSINESS_HOURS: (state, businessHours) => {
        if (state.closestToUser) {
          if (businessHours && businessHours.locations && businessHours.locations[state.closestToUser.id]) {
            const businessHoursCloseToUser = businessHours.locations[state.closestToUser.id]
            return {
              ...state,
              closestToUser: getDerivedBusinessHours(
                mergeDeepRight(state.closestToUser, businessHoursCloseToUser)
              ),
            }
          }
        }

        return state
      },
      SET_LOCATION_CLOSEST_TO_USER_REJECTED: (state, postcodeValidError) =>
        mergeDeepRight(state, {
          error: true,
          pending: false,
          postcodeValidError,
        }),
      SET_LOCATION_SEARCH_DEL_TIME: mergeDeepRight,
      SET_LOCATION_SEARCH_DEL_TIME_FROM: update('searchExpectedDeliveryTimeFrom'),
      SET_LOCATION_SEARCH_DEL_TIME_TO: update('searchExpectedDeliveryTimeTo'),
      SET_LOCATION_SEARCH_PREORDER: update('searchPreOrder'),
      SET_LOCATION_POSTCODE_VALID_ERROR: update('postcodeValidError'),
      SET_CHOOSING_LOCATION: (state, choosingLocation) => mergeDeepRight(state, { choosingLocation, postcodeValidError: '' }),
      TOGGLE_CHOOSING_LOCATION: (state) =>
        mergeDeepRight(state, { choosingLocation: !state.choosingLocation }),
      QUIT_CHOOSING_LOCATION: (state) => mergeDeepRight(state, { choosingLocation: false }),
      SET_LOCATION_SEARCH_POSTCODE: (state, searchPostcode) =>
        mergeDeepRight(state, { searchPostcode, postcodeValidError: '' }),
      SET_LOCATIONS_PENDING: mergeDeepLeft({ error: false, pending: true }),
      SET_LOCATIONS_REJECTED: mergeDeepLeft({ error: true, pending: false }),
      SET_LOCATIONS_FULFILLED: (state, { all, deliveryAvailable, closestToUser }) =>
        mergeDeepRight(state, { all, deliveryAvailable, closestToUser, error: false, pending: false }),
    },
  },
} as const

type ALL_ACTION_NAMES_IN_CONFIG = ACTION_NAMES_CREATOR<typeof initialConfiguration>
type StripAsyncKeyword<T> = T extends `${infer U}_PENDING`
  ? U
  : T extends `${infer U}_FULFILLED`
  ? U
  : T extends `${infer U}_REJECTED`
  ? U
  : T

export type ACTION_NAMES_IN_CONFIG = StripAsyncKeyword<ALL_ACTION_NAMES_IN_CONFIG>
export type ACTION_NAMES = ACTION_NAMES_IN_CONFIG | 'CLEAR_STATE' | 'SET_PERSISTED_STATE' | 'MAKE_PAYMENT'
export type REDUCER_BY_ACTION_NAMES = {
  [K in keyof typeof initialConfiguration]: keyof (typeof initialConfiguration)[K]['actions']
}

type Invert<T> = {
  [K in keyof T]: T[K] extends infer U ? (U extends string ? U : never) : never
}[keyof T extends string ? keyof T : never]

type InvertedMappings<T> = {
  [P in Invert<T>]: {
    [K in keyof T]: P extends T[K] ? K : never
  }[keyof T]
}

export type ACTION_NAMES_BY_REDUCER = InvertedMappings<REDUCER_BY_ACTION_NAMES>
// this doesnt work properly
export type ACTION_NAMES_BY_STATE_PARTIAL = {
  [K in keyof ACTION_NAMES_BY_REDUCER]: ACTION_NAMES_BY_REDUCER[K] extends keyof InitialState
  ? Partial<InitialState[ACTION_NAMES_BY_REDUCER[K]]>
  : never
} & {
  CLEAR_STATE: any
  SET_PERSISTED_STATE: any
  MAKE_PAYMENT: any
}

export type ACTION_PAYLOADS = {
  SET_ORDER_DELIVERY_STATE: DeepPartial<UserOrderState>
}

export type ActionPayload<T> = T extends keyof ACTION_PAYLOADS ? ACTION_PAYLOADS[T] : any

export default initialConfiguration
